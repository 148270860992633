<style></style>

<template>
	<errorContainer :error="erreur">
		<div class="animated fadeIn">
			<b-row v-if="loadContracts">
				<!-- <b-col md="12">
					<h1 :class="[`${!$screen.sm ? 'main-page-title' : ''}`, { 'is-pwa': $isPwa() }]">
						{{ FormMSG(10, 'My Contracts') }}
					</h1>
				</b-col> -->
				<b-col lg="12">
					<contract
						v-for="item in myContracts"
						:key="item.id"
						:contract-input="item"
						:disable-edit="!isFilmSingle"
						:accordion="accordion"
						:hide-send-package-btn="false"
						:show-pay-code="false"
						:fill-class-container="'container-layout'"
						@erreur="erreur = $event"
						@contract:show-body="handleContractShowBody"
					/>
				</b-col>
			</b-row>
			<b-modal
				header-class="header-class-modal-doc-package"
				:title="FormMSG(130, 'Success!')"
				class="modal-success"
				v-model="successModal"
				@ok="successModal = false"
				ok-variant="success"
				ok-only
				cancel-title="close"
			>
				{{ FormMSG(120, 'Your information has been saved.') }}
			</b-modal>

			<loading :active.sync="isLoading" :is-full-page="true" />
		</div>
	</errorContainer>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';

// Import component
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';

import contract from './users/contract';

import isSingleProjectMixin from '@/mixins/isSingleProject.mixin';
import GlobalMixin from '@/mixins/global.mixin';
import { myContracts } from '@/cruds/contract.crud';

export default {
	name: 'MyContracts',
	components: {
		Loading,
		contract
	},
	mixins: [languageMessages, isSingleProjectMixin, GlobalMixin],

	data: function () {
		return {
			// Initialize your apollo data

			erreur: {},
			myContracts: [],
			successModal: false,
			isLoading: false,
			accordion: {}
		};
	},
	computed: {
		async loadContracts() {
			this.myContracts = await myContracts();
			return true;
		}
	},

	methods: {
		handleContractShowBody(payload) {
			//console.log("in handleContractShowBody:",payload)
			this.accordion = payload;
		}
	}
};
</script>
